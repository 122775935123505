import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`
    html, body {
        width: 100vw;
        height: 100vh;
        padding: 0;
        margin: 0;
        font-family: sans-serif;
        font-size: 1.4rem;
    }
    * {
        box-sizing: border-box;
    }
`;

const Flex = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button = styled.div`
    background: red;
    color: white;
    padding: 2rem;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
`;

const Full = styled.div`
    width: 100vw;
    height: 100vh;
    background: red;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const App = () => {
    const [o, setO] = useState(false);

    return (
        <Flex>
            <Global />
            {!o &&
                <div>
                    Hey mxd,&nbsp;
                </div>
            }
            {!o &&
                <Button onClick={
                    () => {
                        setO(true);
                    }
                }>Press this button</Button>
            }
            {o &&
                <Full>
                    Ok, I guess that was it, u ded. 🤷
                    <div>
                        <a href="https://luc.contact">luc was here</a>
                    </div>
                </Full>
            }
        </Flex>
    );
};